.kenes_logo {
  padding-top: 5vh;
  display: flex;
  justify-content: center;
  img {
    width: 55rem;
    height: auto;
  }
}

@media (max-width: 768px) {
  .kenes_logo {
    padding-top: 10vh;
    display: flex;
    justify-content: center;
    img {
      width: 20rem;
      height: auto;
    }
  }
}
