@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
body {
  font-family: "Open Sans Hebrew", serif;
}
table {
  background-color: white;
  max-width: 70vw;
  margin: auto;
}
.bg {
  background-image: url("../../images/woman.png");
  background-color: #cccccc;
  opacity: 0.8;
  margin: auto;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}
.participants_page {
  max-width: 70vw;
  min-height: 30vh;
  margin: auto;
  opacity: 1;
}
