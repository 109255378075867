/*desktop*/
.select_container {
  text-align: right;
  direction: rtl;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 3vh;
  margin-left: 10px;
  width: 15vw;
  border: none;
  outline: none;
}
.select_container_sadna {
  display: inline-block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  text-align: right;
  direction: rtl;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.3);
  margin-bottom: 3vh;
  margin-left: 5vw;
  width: 25vw;
  border: none;
  outline: none;
}
@media (max-width: 768px) {
  .select_container_sad {
    margin-right: 5vw;
    margin-left: 5vw;
  }
  .select_container_sad:last-child {
    margin-bottom: 20vh !important;
  }
}
