.participants_table {
  margin: auto;
  margin-top: 3vh;
  direction: rtl;
  margin-bottom: 10vh;
  background-color: whitesmoke !important;
  .table_frame {
    display: flex;
    flex-direction: column;
    .top_frame {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2% 5vw 0% 5vw;
    }
    .bottom_frame {
      display: flex;
      flex-direction: column;
      align-items: center;
      .react-bootstrap-table-pagination {
        align-self: baseline;
      }
    }
  }
}
.header_row {
  text-align: center;
}
.rows {
  text-align: center;
}

.title {
  text-align: center;
  margin-top: 50px; /* Adjust as needed */
}

.btitle {
  margin: 10px 100px 30px 40px; /* top right bottom left */
  text-align: right;
}

.row-highlight {
  background-color: yellow;
}