.expanded_comp {
  display: flex;
  flex-direction: row;
  .expanded_div {
  }
  
  .button_container {
    margin-right: auto;
  }
  .left_comp {
    display: flex;
    flex-direction: column;
    .sadnaot_gifts {
      display: inline-block;
      text-align: right;
      vertical-align: top;
    }
  }

  .delete_user button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
  }
  
  .delete_user button:hover {
    background-color: #d32f2f;
  }

  .button_container button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    margin: 5px 100px 10px 0px; /* top right bottom left */
  }
  
  .button_container button:hover {
    background-color: #45a049;
  }

  .cancel_button_container button {
    background-color: #e9594f;
    color: white;
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    margin: 5px 100px 10px 0px; /* top right bottom left */
  }
  .cancel_button_container button:hover {
    background-color: #8524bd;
  }



  .details {
    display: inline-block;
    text-align: right;
    vertical-align: top;
  }
}
