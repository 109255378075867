ul {
  list-style-type: none;
  direction: rtl;
  text-align: right;
}
li {
  list-style-type: none;
  direction: rtl;
  text-align: right;
}
