@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);
@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus,
.ant-input-status-warning:not(.ant-input-disabled):not(.ant-input-borderless).ant-input-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-error .ant-input-prefix {
  color: #ff4d4f;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-status-warning:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-affix-wrapper-status-warning .ant-input-prefix {
  color: #faad14;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-error:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number:focus,
.ant-input-number-status-warning:not(.ant-input-number-disabled):not(.ant-input-number-borderless).ant-input-number-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-status-warning .ant-input-number-prefix {
  color: #faad14;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-error:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ff7875;
  box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-error .ant-input-number-prefix {
  color: #ff4d4f;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:hover {
  background: #fff;
  border-color: #faad14;
}
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper:focus,
.ant-input-number-affix-wrapper-status-warning:not(.ant-input-number-affix-wrapper-disabled):not(.ant-input-number-affix-wrapper-borderless).ant-input-number-affix-wrapper-focused {
  border-color: #ffc53d;
  box-shadow: 0 0 0 2px rgba(250, 173, 20, 0.2);
  border-right-width: 1px;
  outline: 0;
}
.ant-input-number-affix-wrapper-status-warning .ant-input-number-prefix {
  color: #faad14;
}
/*desktop*/
@media (min-width: 768px) {
  body {
    font-family: "Open Sans Hebrew", serif;
  }
  .formDiv {
    width: 100%;
    direction: rtl;
    text-align: right;
  }
  .regis_form {
    float: right;
    margin: 0 auto;
    margin-top: 1vh;
    margin-right: 3%;
    min-width: 90%;
  }
  .regis_form h1 {
    text-align: center;
    margin-bottom: 5vh;
  }
  .regis_form label {
    display: inline-block;
    margin-left: 2vw;
    margin-bottom: 10px;
    text-align: right;
    width: 12vw;
  }
  .regis_form input,
  select {
    display: inline-block;
    box-sizing: content-box;
    margin-bottom: 5px;
    text-align: right;
    border: none;
    border-bottom: solid 1px rgba(128, 128, 128, 0.5);
    -webkit-box-shadow: 0 4px 3px -3px rgba(128, 128, 128, 0.5);
    -moz-box-shadow: 0 8px 6px -6px rgba(128, 128, 128, 0.5);
    box-shadow: 0 4px 4px -3px rgba(128, 128, 128, 0.5);
  }
  .regis_form input:focus {
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px black;
    opacity: 1;
    transition: 400ms ease 0ms;
  }
  .select_container {
    display: inline-block;
    text-align: right;
    direction: rtl;
    width: 20vw;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
  }
  .select_container .div_steps {
    margin-bottom: 3vh;
    width: 100%;
  }
  .credit {
    position: absolute;
    bottom: 1px;
    left: 1px;
  }
  .error_div {
    display: inline-block;
    direction: rtl;
    color: red;
  }
  .my-swal {
    z-index: 10001 !important;
  }
  .loading {
    position: relative;
    right: 35%;
  }
  .send_button {
    text-align: center;
  }
  .header_sadnaot {
    font-size: 1.1rem;
    text-align: right;
    font-weight: 600;
    margin: 10px 0%;
  }
  .chosen_sadnas {
    display: flex;
    flex-direction: column;
    margin-bottom: 5%;
  }
  .chosen_sadnas .sadna_row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 2vh;
  }
  .chosen_sadnas .sadna_row .label_row {
    font-weight: 600;
    margin-left: 5%;
    min-width: fit-content;
  }
}
/*mobile*/
@media (max-width: 768px) {
  body {
    font-family: "Open Sans Hebrew", serif;
  }
  .modal_container {
    width: 100%;
    height: 130vh;
    overflow: hidden;
  }
  .formDiv {
    width: 100%;
    min-height: 100%;
    direction: rtl;
    text-align: right;
  }
  .regis_form {
    float: right;
    margin: 0 auto;
    margin-top: 1vh;
    margin-right: 10vw;
    min-width: 90%;
    min-height: 100%;
  }
  .div_steps {
    margin-right: 0vw;
    width: 80% !important;
  }
  .regis_form h1 {
    text-align: center;
    margin-bottom: 5vh;
  }
  .regis_form label {
    display: inline-block;
    margin-left: 4vw;
    margin-bottom: 10px;
    text-align: right;
    width: 60vw;
  }
  .regis_form input,
  select {
    display: inline-block;
    box-sizing: content-box;
    margin-bottom: 5px;
    margin-left: 30px;
    text-align: right;
    border: none;
    border-bottom: solid 1px rgba(128, 128, 128, 0.5);
    -webkit-box-shadow: 0 4px 3px -3px rgba(128, 128, 128, 0.5);
    -moz-box-shadow: 0 8px 6px -6px rgba(128, 128, 128, 0.5);
    box-shadow: 0 4px 4px -3px rgba(128, 128, 128, 0.5);
  }
  .regis_form input:last-child {
    margin-bottom: 5vh;
  }
  .regis_form input:focus {
    outline: none;
    border-top: none;
    border-right: none;
    border-left: none;
    border-bottom: solid 1px black;
    opacity: 1;
    transition: 400ms ease 0ms;
  }
  .select_container {
    text-align: right !important;
    direction: rtl !important;
    width: 60vw !important;
  }
  .select_container .div_steps {
    margin-bottom: 3vh;
    width: 100%;
  }
  .credit {
    position: absolute;
    bottom: 1px;
    left: 1px;
  }
  .error_div {
    display: inline-block;
    direction: rtl;
    color: red;
  }
  .my-swal {
    z-index: 10001 !important;
  }
  .loading {
    position: relative;
    right: 35%;
  }
}
