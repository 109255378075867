.social_media {
  position: fixed;
  bottom: 1vh;
  right: -5vw;

  img {
    width: 15%;
    height: auto;
    margin-right: 1vw;
  }

  @media (max-width: 768px) {
    right: -20vw;
  }
}
